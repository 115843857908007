<template>
    <div class="wrap">
        <el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
                <div class="flex justify-between">
                    <el-form :inline="true" :model="searchForm">
                        <el-form-item label="打卡时间">
                            <el-date-picker
                                    v-model="changeDate"
                                    type="month"
                                    @change="getDate"
                                    value-format="yyyy-MM"
                                    placeholder="选择月">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" @click="loadData()">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
        <div style="margin: 20px 0;color: #ed1c24">默认仅展示当前月的打卡记录</div>
        <el-table :data="tableData" v-loading="loading" max-height="9999">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="nickname" label="微信昵称"></el-table-column>
            <el-table-column prop="name" label="卡项名称"></el-table-column>
            <el-table-column prop="merchant_name" label="打卡门店"></el-table-column>
            <el-table-column prop="created_at" label="打卡时间"></el-table-column>
        </el-table>
        <div class="text-center margin-top">
            <el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
                           :page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
                           @size-change="pageSizeChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import rules from '../../common/common.rules.js'
    export default {
        data() {
            return {
                rules,
                title:'打卡记录',
                changeDate:'',
                loading: false,
                tableData: [],
                searchForm: {
                    act_id:Number(this.$route.params.actId),
                    user_id:Number(this.$route.params.userId),
                    year:undefined,
                    month:undefined,
                    page: 1,
                    page_size: 10
                },
                total: 0
            };
        },
        mounted() {
            this.loadData();
            // vuex 是minxin 进来的 所以在data中访问不到，需要另行设置
            // this.importMember.headers.Authorization = this.vuex_token;
        },
        methods: {
            getDate(){
                console.log(this.changeDate)
                this.searchForm.year = Number(this.changeDate.slice(0,4))
                this.searchForm.month = Number(this.changeDate.slice(this.changeDate.length - 2))
                console.log(this.searchForm.year)
                console.log(this.searchForm.month)
            },
            loadData() {
                this.loading = true;
                this.$api.system.userActLog(this.searchForm).then().then(res => {
                    console.log(res)
                    this.loading = false;
                    this.tableData = res.data.data;
                    this.total = res.data.total;
                });
            },
            pageSizeChange(size) {
                this.searchForm.page_size = size;
            },
            pageChange(page) {
                this.searchForm.page = page;
                this.loadData()
            },
        }
    };
</script>
<style scoped lang="scss">
    .cover {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        margin-right: 5px;
    }
</style>
